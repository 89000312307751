import {Basics} from './basics';

var $dropdownParent = $('#filterModal').length === 0 ?  $(document.body) : $('#filterModal');

function ajaxFieldLists() {
  $('.ajaxFieldList').each(function() {

    // don't set up select2 twice
    if( typeof $(this).data('select2') === 'undefined'){
      $(this).flexSelect2({
        unmodified: true,
        dropdownParent: $dropdownParent,
        minimumInputLength: ($(this).hasClass('searchableFieldList') ? 1 : 0),
        ajax: {
          url: Flexmls.portalSlugPrefix + '/search/field_lists',
          dataType: 'json',
          data: function (params) {
            var select = $(this);
            var ajaxData = {
              search: params.term,
              domain: select.attr('data-domain'),
              field_name: select.attr('data-field-name'),
              page: params.page || 1
            }

            if (Basics.proptype().length > 0 && Basics.proptype().val() !== null){
              ajaxData.property_type = Basics.proptype().val();
            } else if(Basics.propertyClass().length > 0 && Basics.propertyClass().val() !== null){
              ajaxData.property_class = Basics.propertyClass().val();
            }

            if (Basics.mls_id().length > 0 && Basics.mls_id().val() !== null) {
              ajaxData.mls_ids = Basics.mls_id().val();
            }

            return ajaxData;
          },
          processResults: function (data) {
            var results = $.map(data.results, function(d) {
              return {id: d.Value ,text: d.Name};
            });
            return {
              results: results,
              pagination: {
                more: data.pagination.current_page < data.pagination.total_pages
              }
            };
          }
        }
      });
    }
  });
}

function formatState(state) {
  const option = $(state.element);
  let color = option.data('color');

  if (!color) {
    return state.text;
  }

  return $(`<span data-shape="${state.id}" style="color: ${color}">${state.text}</span>`);
}

function formatOverlyShapeSelectionBlock(state) {
  // The state that comes from adding something via the locations dropdown vs coming
  // from the overlays dropdown is different.
  if (state.notFromLocations) {
    return $(`<span data-shape="${state.id}" style="color: ${state.color}">${state.text}</span>`);
  } else {
    return formatState(state)
  }
}

function formatOverlayShapeResultsDropdown(data) {
    if (!data.id) {
      return data.text
    }
    var $result = $(`<span data-shape="${data.id}" style="color: ${data.color}">${data.text}</span>`);
    return $result;
}

function multiSelectDropdowns() {
  var excludedDropdowns = '.boolean-group-operator-select, #locationSearch, .form-control, .ajaxFieldList';
  // Setup Select2 on multi-select elements
  $('select').not(excludedDropdowns).each(function() {
    if($(this).prop('multiple')){
      // don't set up select2 twice
      if( typeof $(this).data('select2') === 'undefined'){
        if ($(this).attr('id') === 'overlayShapesSelect') {
          // MapOverlayShape values are retrieved diffrent from other location field
          // drop downs because it is not an actual field on listings.
          $(this).flexSelect2({
            unmodified: true,
            dropdownParent: $dropdownParent,
            minimumInputLength: 1,
            ajax: {
              url: Flexmls.portalSlugPrefix + '/search/overlays/shapes',
              dataType: 'json',
              data: function (params) {
                var select = $(this);
                var ajaxData = {
                  _filter: "Name Eq '*" + params.term + "*'",
                }
                return ajaxData;
              },
              processResults: function (data) {
                var results = $.map(data, function(shape) {
                  // The dropdown should save/show the color of the overlay
                  return {id: "'" + shape.Id + "'" ,text: shape.Name, color: "#" + shape.Color, notFromLocations: true};
                });
                return {
                  results: results
                }
              }
            },
            templateResult: formatOverlayShapeResultsDropdown,
            templateSelection: formatOverlyShapeSelectionBlock
          });
        } else {
          $(this).flexSelect2({
            unmodified: true,
            dropdownParent: $dropdownParent,
            templateResult: formatState,
            templateSelection: formatState,
          });
        }
      }
    }
  });

}

export var fieldEnhancements = {
  ajaxFieldLists: ajaxFieldLists,
  multiSelectDropdowns: multiSelectDropdowns
};

import Overlay from './overlay'
import OverlayMapType from './overlay_map_type'
import {OverlayLegend} from './overlay_legend'
import {searchResults} from '../search_results/search_results'
import {translations} from '../translations.js.erb'
import GlobalOverlayManager from '../global_overlay_manager'

import {Moarify} from '@programming/moarify'

var customMapControls = (function() {

  var allowOverlayFiltering = ($('body').attr('mweb-map-overlay-filtering') === 'true');

  var drawingManager;
  var overlayManager;
  var overlayLegend;
  var googleMap;
  var overlayMeta = {};
  var selectedOverlays = {
    my: [],
    mls: []
  };
  var visibility = {
    listings: true
  }

  function init(options) {

    googleMap = options.map;
    drawingManager = options.drawingManager;
    overlayManager = options.overlayManager;
    overlayLegend = new OverlayLegend();

    // still used on the listing detail page
    $('#mapTypeBtn').click(function(e) {
      e.preventDefault();
      var newType = googleMap.getMapTypeId() === 'roadmap' ? 'hybrid' : 'roadmap';
      setMapType(newType);
    });

    restorePreviouslySelectedValues();

    bindControls();

    if( !Modernizr.touch ) {

      $('#mapZoomButtons').show();

      $('#mapZoomIn').click(function() {
         googleMap.setZoom(googleMap.getZoom() + 1);
      });
      $('#mapZoomOut').click(function() {
         googleMap.setZoom(googleMap.getZoom() - 1);
      });
    }

  }

  function setMapType(newType) {
    if (newType === 'hybrid'){
      googleMap.setMapTypeId(google.maps.MapTypeId.HYBRID);
    } else {
      googleMap.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    }
  }

  function bindControls() {
    $(document).on('change', '.mapTypeControl', function() { setMapType($(this).val()); });
    $(document).on('change', '.overlayCheckbox', handleOverlayCheckboxChange);
    $(document).on('change', '.mlsOverlayCheckbox', handleMlsOverlayCheckboxChange);

    $(document).on('click', '#overlayKeyBtn, .overlaysPopover, .moreMapToolsBtn, .shapesPopover', function(e) {
      // on touch devices, preview popups won't disapear on mouseleave
      searchResults.map().deleteTemporaryMarkersAndStuff();
    })

    $('.overlaysPopover').flexPopover({html: true, closeButtonText: translations.g.done, popoverClass: 'map-overlays-popover'});
    $('.moreMapToolsBtn').flexPopover({html: true, closeButtonText: translations.g.done});
    $('#overlayKeyBtn').flexPopover({html: true, closeButtonText: translations.g.done, popoverClass: 'overlay-legend-popover'});
    $('.shapesPopover').flexPopover({html: true, popoverClass: 'map-shapes-popover'});

    $('.overlaysPopover, .moreMapToolsBtn, .shapesPopover, #overlayKeyBtn').on('show.bs.popover', function() {
      $(this).addClass('active');
    });

    $('.overlaysPopover, .shapesPopover, .moreMapToolsBtn, #overlayKeyBtn').on('hide.bs.popover', function() {
      $(this).removeClass('active');
    });

    $('.overlaysPopover').on('inserted.bs.popover', function() {
      loadOverlayList();
    });

    $(document).on('moarify:itemsAdded', '.mlsOverlaysList', function() {
      if (allowOverlayFiltering) {
        GlobalOverlayManager.getSelectedMlsOverlays().forEach(function(overlay) {
          $('#'+overlay.Id).prop('checked', true);
        });
      } else {
        selectedOverlays.mls.forEach(function(id) {
          $('#'+id).prop('checked', true);
        });
      }
    });

    $(document).on('moarify:itemsAdded', '.myOverlaysList', function() {
      selectedOverlays.my.forEach(function(id) {
        $('#'+id).prop('checked', true);
      });
    });

    $(document).on('change', '#listing-visibility', handleListingVisibilityChange);
    $(document).on('change', '#drawn-shapes-visibility', handleDrawnShapesVisibilityChange);
  }

  function loadOverlayList() {
    var mlsOverlaysList = new Moarify({
      appendTo: $('.mlsOverlaysList'),
      scrollElement: $('.overlaysScrollContainer'),
      infiniteScroll: true,
      useSessionStorage: false,
      currentPage: 0,
      dataType: 'json',
      ajaxUrl: window.routes.mls_overlays_path(),
      processResponse: function(data) {
        data.data.forEach(function(overlay) {
          overlayMeta[overlay.Id] = overlay;

          // Uncomment this for testing overlay legends. It will add legend data 
          // to any mls overlay that is selected. 
          // 
          // overlayMeta[overlay.Id].Legends = [
          //   {Color: "FA928F", Description: "A: Detailed analysis not performed. 26% chance over 30yr mortgage"},
          //   {Color: "C7AB8F", Description: "AE: Base elevations are provided. 26% chance over 30yr mortgage"},
          //   {Color: "FABFE7", Description: "AH: Pond flood hazard"},
          //   {Color: "C2928F", Description: "AO: River or stream flood hazard"},
          //   {Color: "BFFFCF", Description: "V: Flooding with wave hazard. Base elevations not provided"},
          //   {Color: "7EE3E3", Description: "VE: Flooding with wave hazard"},
          // ];
          // overlayMeta[overlay.Id].DataSource = {
          //   Url: "http://www.floodmaps.fema.gov/NFHL/status.shtml",
          //   Label: "FEMA National Flood Hazard Layers"
          // };

        });
        return data.html;
      }
    });
    mlsOverlaysList.run();


    var myOverlaysList = new Moarify({
      appendTo: $('.myOverlaysList'),
      scrollElement: $('.overlaysScrollContainer'),
      infiniteScroll: true,
      useSessionStorage: false,
      currentPage: 0,
      dataType: 'json',
      ajaxUrl: window.routes.my_overlays_path(),
      processResponse: function(data) {
        data.data.forEach(function(overlay) {
          overlayMeta[overlay.Id] = overlay;
        });
        return data.html;
      }
    });
    myOverlaysList.run();


    $('.overlaysScrollContainer').css('max-height', $('#map').height() - 100)
  }

  function restorePreviouslySelectedValues() {

    $('.overlaysPopover').on('inserted.bs.popover', function() {
      // restore map type
      var mapType = googleMap.getMapTypeId();
      $('.mapTypeControl[value="' + mapType + '"]').prop('checked', true);
    });

    $('.moreMapToolsBtn').on('inserted.bs.popover', function() {
      $('#listing-visibility').prop('checked', visibility.listings);
      $('#drawn-shapes-visibility').prop('checked', drawingManager.drawingsVisible());
    });
  }

  function handleOverlayCheckboxChange() {
    var overlayId = $(this).attr('id');
    var visible = $(this).prop('checked');
    var meta = overlayMeta[overlayId];

    if(visible){
      // If we've already loaded the geometry, use it
      if (meta.Geometries) {
        selectedOverlays.my.push(overlayId);
        var overlay = new Overlay(meta);
        overlayManager.addOverlay(overlay, {color: false});
      } else {
        // Otherwise fetch it
        showPageLoadingMsg();
        const request = new Request(window.routes.overlay_path(overlayId), { method: 'GET' });
        fetch(request).then(response => {
          return response.json();
        }).then(json => {
          if (json["success"]) {
            selectedOverlays.my.push(overlayId);
            overlayMeta[overlayId] = JSON.parse(json["data"]).attributes;
            var overlay = new Overlay(overlayMeta[overlayId]);
            if (allowOverlayFiltering) {
              GlobalOverlayManager.addMyMapOverlayToMap(overlay, {color: false});
            } else {
              overlayManager.addOverlay(overlay, {color: false});
            }
          } else {
            toast.error("Error loading overlay geometry", false);
            $(this).prop('checked', false);
          }
          hidePageLoadingMsg();
        });
      }
    } else {
      // Remove the item from the selected my overlays.  This is needed in old
      // and new code so that the overlays popover will not "recheck" the unselected
      // item the next time we open the popover
      var index = selectedOverlays.my.indexOf(overlayId);
      selectedOverlays.my.splice(index, 1);
      // Now we can remove the shapes from the map either the old or new way.
      if (allowOverlayFiltering) {
        GlobalOverlayManager.removeMyMapOverlayFromMap(overlayId, false);
      } else {
        overlayManager.removeOverlay(overlayId, false);
      }
    }
  }

  function handleMlsOverlayCheckboxChange() {
    var overlayId = $(this).attr('id');
    var meta = overlayMeta[overlayId]
    var overlayName = meta.LayerName
    var visible = $(this).prop('checked');

    if(visible){
      if (allowOverlayFiltering) {
        GlobalOverlayManager.addMlsOverlay(overlayId, overlayName)
      } else {
        selectedOverlays.mls.push(overlayId);
        googleMap.overlayMapTypes.push(new OverlayMapType(overlayName));
      }
      if(meta.Legends.length > 0) {
        overlayLegend.add(meta);
      }
    } else {
      if (allowOverlayFiltering) {
        GlobalOverlayManager.removeMlsOverlay(overlayId)
      } else {
        var index = selectedOverlays.mls.indexOf(overlayId);
        selectedOverlays.mls.splice(index, 1);
        googleMap.overlayMapTypes.removeAt(index);
      }
      overlayLegend.remove(overlayId);
    }
  }

  function handleListingVisibilityChange() {
    visibility.listings = $(this).prop('checked');
    $('#map').toggleClass('hide-listings', !visibility.listings);
  }

  function handleDrawnShapesVisibilityChange() {
    var visible = $(this).prop('checked');
    drawingManager.toggleVisibility(visible);
  }

  return {
    init: init
  };

})();

export default customMapControls;
